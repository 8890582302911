.navContainer {
  display: flex;
  height: -webkit-fill-available;
  width: 100%;
  background: rgb(236, 235, 239);
  overflow-y: auto;
  overflow-x: auto;
}

.pageContainer {
  /* width: 100%;
  background: rgb(236, 235, 239); */
  align-content: flex-start;
}

.navMenu {
  padding: 15px;
  color: white;
  background: rgba(41, 19, 46, 0.95);
}

.pageHeader {
  padding: 15px;
  margin-bottom: 15px;
}

.button {
  color: white;
  border-color: white;
}
