.serviceNavigationContainer {
  /* display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #29132e; */
  /* height: 100%; */
  /* height: initial; */
  background: #ccc;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  /* width: 100wh; */
  width: 100vw;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: "hidden"
}
.content {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  background-color: #fff;
}
.rightContent {
  display: flex;
  /* width: 100%; */
  flex: 1;
  min-height: 100vh;
  max-height: 100vh;
  background: rgb(236, 235, 239);
  overflow: hidden;
  flex-flow: column nowrap;
}
.pageContent {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 21px;
  overflow: auto;
  box-sizing: border-box;
  flex-wrap: nowrap;
  width: 100%;
}
