.selected {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.menu {
  width: 200px;
}

.select {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 110px;
  border-radius: 3px;
  height: 35px;
}

.arrowIcon {
  font-size: 28px;
}

.menuItem {
  justify-content: space-between;
}
