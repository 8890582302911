.siteDetailsError {
  font-size: 12px;
  padding: 0;
  margin: 0;
  width: 100%;
  color: red;
}

.siteDetailsLabel {
  display: flex;
  align-items: center;
  min-width: 220px;
  color: rgba(0, 0, 0, 0.87);
}

.siteDetailsFields {
  width: 100%;
}

.selectField label {
  margin-top: -16px;
}

.selectField {
  width: 100%;
  height: 45px;
}

.inputRoot {
  margin: 0 !important;
  height: 45px;
}

.dataRow {
  display: flex;
  flex-flow: column nowrap;
  padding: 7px 0;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.flexBox {
  display: flex;
}

.columnFlexBox {
  width: 100%;
  flex-flow: column nowrap;
}

.removeBorder {
  border: none;
}

.disabledStyle {
  cursor: not-allowed;
  opacity: 0.4;
}
