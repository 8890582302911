.paperStyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  margin-right: 20px;
}

.inputFiledStyle {
  border: 1px solid black;
  border-radius: 4px;
  width: 30%;
  font-size: 14px;
  padding: 2px 10px;
  margin-top: 12px;
}

.saveButtonStyle {
  padding: 0;
  margin: 0;
  background-color: #331e38;
  max-width: 180px;
  min-width: 160px;
}

.buttonsContainer {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 200px;
}

.editCustomerPageContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pageContainerAsComponent {
  overflow: unset;
}

.editCustomerPaper {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  flex: 1;
  max-width: 700px;
  /* background-color: white; */
  min-width: 400px;
  margin-right: 20px;
}

.customerDetailsHeader {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 4px 20px;
  box-shadow: 0px 5px 5px 0px rgba(214, 206, 214, 1);
  box-sizing: border-box;
}

.loader {
  height: 25px;
  width: 25px;
  color: #331e38;
}

.editCustomerTitle {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.customerDetailsIcons {
  font-size: 20px;
}

.customerDetailsFieldsContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  overflow: auto;
}
.customerDetailsFieldsContainer label {
  margin-top: -5px;
}

.saveBtn {
  display: flex;
  align-self: flex-end;
  margin: 1rem;
  justify-content: flex-end;
}

.addButtonsContainer {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 200px;
}

.usageDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3rem;
  align-items: center;
}

.divItem {
  width: 60px;
  text-align: center;
}

.usageDivBorder {
  border-bottom: 1px solid #ccc;
}

.smsWarning {
  color: red;
}

.siteDetailsLabel {
  display: flex;
  align-items: center;
  min-width: 200px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 10px;
}

.arrowContainer {
  min-width: 20;
  height: 1;
  border-bottom: 1px solid #d5d2d5;
  margin-left: -1px;
}

.arrow {
  font-size: 91px;
}

.rotateArrow {
  transform: rotate(-90deg);
}

.smallCheckbox {
  margin-right: 10px;
  padding: 0;
}

.smallCheckbox span {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background-color: #fff;
}

.labelRoot {
  display: flex;
  align-items: center;
  padding: 9px;
  padding-left: 0;
}

.labelText {
  font-weight: inherit;
  flex-grow: 1;
  font-size: 15;
}

.fullWidthPaper {
  max-width: unset;
}

.title {
  background-color: rgb(236, 235, 239);
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  padding: 4px 24px;
}

.titleContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogContent {
  margin: "1rem";
  overflow-y: "auto";
}

.iconBtnStyle {
  position: absolute;
  right: 24px;
  top: 16px;
}

.switchContainer {
  display: flex;
  justify-content: space-between;
}

.inputLabel {
  width: 100%;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.errorMessage {
  color: red;
  line-height: 35px;
}

.rowItem {
  padding: 10px 0;
  width: 100%;
  border-bottom: 1px solid #ccc;

  .rowDetails {
    opacity: 60%;
  }
}

.clickable {
  cursor: pointer;
}
