.paper {
  width: calc(100% - 64px);
  height: 100%;
  padding: 2rem;
  max-height: calc(100% - 130px);
  display: flex;
  flex-flow: column nowrap;
  min-height: 740px;
}


.supportContanier {
  justify-content: space-between;
  height: 60vh;
}

.title.title {
  font-size: 1rem;
}

.supportItem {
  border: 1px solid grey;
  height: 10rem;
  margin-bottom: 20px;
}

.actionWrapper {
  display: flex;
  justify-content: space-around;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}


.findBtn {
  margin: 1rem auto;
  padding: 0.625rem;
  width: 20%;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  background: #29abe2;
  border-style: none;
  border-radius: 3px;
  cursor: pointer;
}

.inputStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
}

.btnWrpper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dialog {
  /* height: 15.625rem; */
  width: 500px;
  border-radius: 7px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #d5d2d5
}

.dialogContent {}

.dialogActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.titleContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 9px 5px 24px;
  color: #29132e;
  border-bottom: 1px solid #d5d2d5;
  background-color: #f6f6f7
}

.userNameContiner {
  display: flex;
  margin: 15px 0px;
}

.userNameText {
  font-size: 18px;
  color: #29132e
}

.boldText {
  font-weight: bold;
  margin-right: 10px;
}

.actionsContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin: 10px 0px
}